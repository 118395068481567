import React, {useMemo} from 'react'
import { useQueryParam, StringParam } from "use-query-params";
import {graphql} from 'gatsby'
import usePrismicHomepage from "../../hooks/usePrismicHomepage";
import SinglePageTemplate from "../../components/singlePageTemplate";

const Announcements = () => {
    const [id] = useQueryParam('id', StringParam)
    const data = usePrismicHomepage();
    const announcement = useMemo(() => data.announcements.find(item => item.id === id), [id])
    return (
        <SinglePageTemplate
            title={announcement?.title}
            route="announcements"
            image={announcement?.image}
            description={announcement?.description}
            usefulLinks={announcement?.usefulLinks}
        />
    )
}

export default Announcements

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`