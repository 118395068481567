import {
  graphql,
  useStaticQuery,
} from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const usePrismicHomepagae = () => {
  const { language } = useI18next();
  const newLang =
    language === "en"
      ? "en-us"
      : language === "ru"
      ? "ru"
      : "hy-am";

  const response =
    useStaticQuery(graphql`
      query PrismicHomepage {
        allPrismicHomepage(
          filter: {
            tags: { in: "home_page" }
          }
        ) {
          nodes {
            lang
            data {
              body {
                ... on PrismicHomepageDataBodyAnnouncementsAndUsefulLinks {
                  id
                  slice_type
                  primary {
                    id
                    title {
                      text
                    }
                    description {
                      richText
                    }
                    date
                    image {
                      url
                    }
                    useful_link_title {
                      text
                    }
                    useful_link_description {
                      richText
                    }
                  }
                  items {
                    link_title
                    link_href {
                      url
                    }
                  }
                }
              }
              slides {
                primarybutton
                secondarybutton
                primarybuttonlink
                secondarybuttonlink
                bottom_text
                title {
                  text
                }
                cover {
                  url
                }
              }
              aboutbuttontext
              aboutdescription {
                richText
              }
              abouttitle {
                text
              }
              aboutvideolink {
                url
              }
              aboutvideoimagelink {
                url
              }
              findoutimage {
                url
              }
              findoutbuttontext
              findoutdescription {
                richText
              }
              fintouttitle {
                text
              }
              morebutton
              announcements {
                text
              }
              your_step_title {
                text
              }
            }
          }
        }
      }
    `);
  return sanitize(
    response.allPrismicHomepage.nodes.filter(
      (item) => item.lang === newLang
    )[0].data
  );
};

const sanitize = (response) => {
  return {
    yourStepTitle:
      response.your_step_title.text,
    aboutButtonText:
      response.aboutbuttontext,
    aboutDescription:
      response.aboutdescription
        .richText,
    aboutTitle:
      response.abouttitle.text,
    aboutVideoLink:
      response.aboutvideolink.url,
    aboutVideoImageLink:
      response.aboutvideoimagelink.url,
    findOutButtonText:
      response.findoutbuttontext,
    findOutDescription:
      response.findoutdescription
        .richText,
    findOutImage:
      response.findoutimage.url,
    findOutTitle:
      response.fintouttitle.text,

    slides: response.slides.map(
      (slide) => ({
        primaryButton:
          slide.primarybutton,
        primaryButtonLink:
          slide.primarybuttonlink,
        secondaryButton:
          slide.secondarybutton,
        secondaryButtonLink:
          slide.secondarybuttonlink,
        bottomText: slide.bottom_text,
        title: slide.title.text,
        image: slide.cover.url,
      })
    ),
    announcements1:
      response.announcements.text,
    moreButton: response.morebutton,
    announcements: response.body.map(
      (announcement) => ({
        id: announcement.primary.id,
        title:
          announcement.primary.title
            .text,
        description:
          announcement.primary
            .description.richText,
        image:
          announcement.primary.image
            .url,
        date: announcement.primary.date,
        usefulLinks: {
          id: announcement.primary.id,
          title:
            announcement.primary
              .useful_link_title.text,
          description:
            announcement.primary
              .useful_link_description
              .richText,
          links: announcement.items.map(
            (link) => ({
              title: link.link_title,
              href: link.link_href.url,
            })
          ),
        },
      })
    ),
  };
};

export default usePrismicHomepagae;
